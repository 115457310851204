<template>
    <div>
        <validation-provider #default="validationContext" :name="name" :rules="validations">
            <v-select 
            :disabled="disabled==1?'disabled':false"
            
            :id="id"  
            class="w-100"
             v-b-tooltip.hover.bottomleft="tooltip != null ? tooltip : ''"
             
            v-model="content" :options="options" @input="(code) => changevalue(code)" />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
        </validation-provider>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormDatepicker,
    BForm,
    BFormGroup,
    VBTooltip,
    BFormTextarea,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
} from 'bootstrap-vue'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required,
    amount
} from '@validations'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
export default {
    props: ['name', 'valuex', 'keys', 'label', 'disabled','options', 'id', "tooltip",'validations'],
    components: {
        BCol,
        BCard,
        BRow,
        BForm,
        BCardBody,
        BFormGroup,
        BFormCheckbox,
        BFormTextarea,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        ValidationProvider,
        ValidationObserver,
        BFormDatepicker,
        vSelect,
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
    },
    setup(props, {
        emit
    }) {
        const {
            refFormObserver,
            getValidationState
        } = formValidation();
        return {
            refFormObserver,
            getValidationState,
        };
    },
    data() {
        return {
            required,
            amount,
            content:this.valuex,
        }
    },
    mounted() {
        this.$watch('$props', ()=>{
              this.content=this.valuex
          }, { deep: true })
        this.content = this.getvalue(this.valuex,this.options)
        this.changevalue(this.content)
    },
    methods: {
        getvalue(val,list){
            for(var i=0;i<list.length;i++){
                if(list[i].id==val){
                    return list[i]
                }
            }
            return '';
        },
        changevalue(content,type=0){
            if(content!=null){
                this.$emit('updatedata',content);
            }
            this.content=content;
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
